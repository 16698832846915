import { PaletteOptions } from "@mui/material";

export const lightPalette: PaletteOptions = {
  mode: "light" as PaletteType,
  background: {
    default: "#ffffff",
    paper: "white",
  },
  primary: {
    main: "#5C2DD3",
    dark: "#3A167F",
    light: "#e2deeb",
    contrastText: "#FFFFFF",
  },
  secondary: {
    dark: "#909090",
    main: "#D8D8D8",
    light: "#E5E5E5",
    contrastText: "#d8d9dc",
  },
  info: {
    main: "#F6F6F6",
    dark: "#eeeeee",
    light: "#EBECEF",
  },
  text: {
    primary: "#000000",
    secondary: "#2F2F2F",
    disabled : "#b0b0b0"
  },
  success: {
    main: "#0CB277",
    light: "#0CB2771A",
    dark: "#ceefe3",
  },
  green: {
    main: "#25b255",
    light: "#80dbbb",
    dark: "",
    contrastText: "",
  },
  warning: {
    main: "#E02D17",
    dark: "#FF9000",
    light: "#fac22d",
  },
  orange: {
    main: "#FF7900",
    light: "#FEA500",
    dark: "#F07B0F",
    contrastText: "#fdf1e7",
  },
  error: {
    main: "#d32f2f",
    dark: "#cc1a36",
    light: "#ee8a87",
    contrastText: "#ED2939",
  },
  lightPurple: {
    main: "#f0ecfa",
    light: "#D4D3DC",
    dark: "#e7e4ef",
    contrastText: "#EDE7F9",
  },
  violet: {
    main: "#704AD1",
    light: "#F1EDFA",
    dark: "#3A167F",
    contrastText: "#ECEBFF",
  },
  gray: {
    main: "#828282",
    light: "#e9e5f2",
    dark: "#222124",
    contrastText: "#555555",
  },
  customLightGrey: {
    main: "#F6F6F6",
    light: "#f0f0f0",
    dark: "#EEEDED",
    contrastText: "#d8d7d7",
  },
  customGrey: {
    main: "#DADADA",
    light: "#F9F9F9",
    dark: "#EDF2F4",
    contrastText: "#B9BBBF",
  },
  blue: {
    main: "#274896",
    light: "#EEF3FF",
    dark: "",
    contrastText: "#fff",
  },
  customBlue: {
    main: "#2D5BFF",
    light: "#2D5BFF1A",
    dark: "#EDF2F4",
    contrastText: "#fff",
  },
  neutral: {
    main: "#000000",
    light: "#FFFFFF",
    dark: "#BEBEBE",
    contrastText: "#3C454B",
  },
  common: {
    white: "#FFFFFF",
    black: "#000000",
  },

  border: {
    main: "#0000001A",
    contrastText: "",
    dark: "",
    light: "#0000001A",
  },
  divider: "#E0E0E0"
};

export const darkPalette: PaletteOptions = {
  mode: "dark" as PaletteType,
  background: {
    default: "#0D253F",
    paper: "#0b2b4d",
  },
  primary: {
    main: "#41D4FD",
    dark: "#3700B3",
    light: "#41D4FD1A",
    contrastText: "#000000",
  },
  secondary: {
    main: "#05192E",
    dark: "#030E1A",
    light: "#0D253F",
    contrastText: "#ffffff",
  },
  info: {
    main: "#282828",
    dark: "#363539",
  },
  text: {
    primary: "#fff",
    secondary: "#B0B0B0",
    disabled : "#6F6F6F"
  },
  success: {
    main: "#03A04F",
    light: "#214B39",
    dark: "#004D32",
  },
  green: {
    main: "#66BB6A",
    light: "#81C784",
    dark: "#338A3E",
    contrastText: "#FFFFFF",
  },
  warning: {
    main: "#FFA000",
    dark: "#C77800",
    light: "#FFC046",
  },
  orange: {
    main: "#FF6D00",
    light: "#FF8F00",
    dark: "#FF3D00",
    contrastText: "#FDF1E7",
  },
  error: {
    main: "#d32f2f",
    dark: "#B00020",
    contrastText:"#ED2939"
  },
  violet: {
    main: "#9F67FF",
    light: "#110329",
    dark: "#3A167F",
    contrastText: "#ECEBFF",
  },
  lightPurple: {
    main: "#14022b",
    light: "#A28ACA",
    dark: "#3B2A5A",
    contrastText: "#DADADA",
  },
  gray: {
    main: "#b6b6b6",
    light: "#EBECEF",
    dark: "#05192e",
    contrastText: "#171717",
  },
  customLightGrey: {
    main: "#333333",
    light: "#4F4F4F",
    dark: "#1A1A1A",
    contrastText: "#E5E5E5",
  },
  customGrey: {
    main: "#424242",
    light: "#0b2b4d", 
    dark: "#212121",
    contrastText: "#B9BBBF",
  },
  blue: {
    main: "#90CAF9",
    light: "#2D5BFF",
    dark: "#1A237E",
    contrastText: "#FFFFFF",
  },
  customBlue: {
    main: "#2DA8FF",
    light: "#2D5BFF1A",
    dark: "#EDF2F4",
    contrastText: "#fff",
  },
  neutral: {
    main: "#FFFFFF",
    light: "#333333",
    dark: "#BEBEBE",
    contrastText: "#1E1E1E",
  },
  common: {
    white: "#FFFFFF",
    black: "#000000",
  },

  border: {
    main: "#0D253F",
    contrastText: "",
    dark: "",
    light: "#FFFFFF80",
  },

  divider: "#FFFFFF80"

};

export type PaletteType = "light" | "dark";
export type ThemeMode = "system" | "light" | "dark";
export const THEME_VALUES = { SYSTEM: "system", LIGHT: "light", DARK: "dark" };
