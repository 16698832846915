import { PaletteColor, createTheme, ThemeOptions, PaletteOptions } from "@mui/material";
import avenirNext from "@assets/fonts/avenir-next-regular.ttf";
import { darkPalette, lightPalette, PaletteType } from "@/theme/palettes.ts";

interface CustomPalletOptions extends PaletteOptions {
  primary: PaletteColor;
  secondary: PaletteColor;
}
// Extend the default theme to include custom colors
declare module "@mui/material/styles" {
  interface Palette {
    customGrey: PaletteColor;
    orange: PaletteColor;
    customBlue: PaletteColor;
    customLightGrey: PaletteColor;
    neutral: PaletteColor;
    lightPurple: PaletteColor;
    blue: PaletteColor;
    green: PaletteColor;
    gray: PaletteColor;
    border: PaletteColor;
    primary: PaletteColor;
  }

  interface PaletteOptions {
    customGrey: PaletteColor;
    orange: PaletteColor;
    customBlue: PaletteColor;
    customLightGrey: PaletteColor;
    neutral: PaletteColor;
    lightPurple: PaletteColor;
    blue: PaletteColor;
    green: PaletteColor;
    gray: PaletteColor;
    border: PaletteColor;
  }
}

const palettes: Record<PaletteType, CustomPalletOptions> = {
  light: lightPalette as CustomPalletOptions,
  dark: darkPalette as CustomPalletOptions,
};

export const generateTheme = (mode: PaletteType): ThemeOptions => ({
  palette: palettes[mode],
  shadows:
    mode === "light"
      ? [
          "none", // 0
          "rgba(0, 0, 0, 0.16) 0px 1px 4px;", // 1
          "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;", // 2
          "rgba(0, 0, 0, 0.1) 0px 4px 12px;", // 3
          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;", // 4
          "0px 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);", // 5
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
        ]
      : [
          "none",
          "rgba(255, 255, 255, 0.1) 0px 1px 4px;",
          "rgba(255, 255, 255, 0.2) 0px 2px 8px 0px;",
          "rgba(255, 255, 255, 0.1) 0px 4px 12px;",
          "rgba(255, 255, 255, 0.25) 0px 6px 12px -2px, rgba(255, 255, 255, 0.3) 0px 3px 7px -3px;", // 4
          "0px 5px 5px -3px rgba(255, 255, 255, 0.20), 0px 8px 10px 1px rgba(255, 255, 255, 0.14), 0px 3px 14px 2px rgba(255, 255, 255, 0.12);", // 5
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
          "none",
        ],
  typography: {
    h3: {
      fontSize: "24px",
      fontWeight: "700",
    },
    h4: {
      fontSize: "18px",
      fontWeight: "700",
    },
    h5: {
      fontSize: "16px",
      fontWeight: "400",
    },
    h6: {
      fontSize: "14px",
      fontWeight: "600",
    },
    subtitle1: {
      fontSize: "12px",
      fontWeight: "600",
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: "400",
    },
    fontFamily: "Avenir Next, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: "none",
          fontWeight: "500",
          "&:hover": {
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          },
        },
        contained: {
          backgroundColor:
            mode === "dark" ? palettes[mode].secondary.main : palettes[mode].primary.main,
          color:
            mode === "dark" ? palettes[mode].primary.main : palettes[mode].primary.contrastText,
          "&:hover": {
            backgroundColor:
              mode === "dark" ? palettes[mode].secondary.dark : palettes[mode].primary.dark,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "solid 1px #DADADA",
          "&:hover": {
            boxShadow: "-7px 7px 15px #DADADA44",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
              font-family: 'Avenir Next, sans-serif';
              src: url(${avenirNext}) format('truetype');
          `,
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor:
                mode === "dark"
                  ? `${palettes[mode].neutral.main}DD`
                  : `${palettes[mode].neutral.main}5A`,
            },
            "&:hover fieldset": {
              borderColor: palettes[mode].neutral.main,
            },
            "&.Mui-focused fieldset": {
              borderColor: palettes[mode].primary.main,
            },
            "&.Mui-disabled fieldset": {
              borderColor: palettes[mode].text?.disabled,
              color: palettes[mode].text?.disabled,
            },
          },
          "& .MuiInputLabel-root": {
            color:
              mode === "dark"
                ? `${palettes[mode].neutral.main}BB`
                : `${palettes[mode].neutral.main}5A`,
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: palettes[mode].primary.main,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor:
              mode === "dark"
                ? `${palettes[mode].neutral.main}DD`
                : `${palettes[mode].neutral.main}5A`,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: palettes[mode].neutral.main,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: palettes[mode].primary.main,
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: palettes[mode].text?.disabled,
          },
        },
        icon: {
          color:
            mode === "dark"
              ? `${palettes[mode].neutral.main}BB`
              : `${palettes[mode].neutral.main}5A`,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color:
            mode === "dark"
              ? `${palettes[mode].neutral.main}BB`
              : `${palettes[mode].neutral.main}5A`,
          "&.Mui-focused": {
            color: palettes[mode].primary.main,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: palettes[mode].background?.default,
          backgroundImage: "none",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
          backgroundColor: palettes[mode].background?.default,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundImage: "none",
          backgroundColor: palettes[mode].background?.default,
        },
      },
    },
  },
});

const theme = createTheme(generateTheme("light"));

export default theme;
